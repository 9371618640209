@import "../../../data/styles.css";

.works-body {
	margin-bottom: 15px;
}

.work {
	display: flex;
	padding-bottom: 40px;
	width: 30vw;
	margin-bottom: 3%;
}

.work-image {
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 6px solid white;
	box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.work-title {
	font-size: 15px;
	font-weight: 700;
	padding-left: 20px;
	margin-top: -3px;
	color: var(--secondary-color);
}
.work-secondary {
	display: flex;
	width: 30vw;
	/* flex-direction: column; */
	position: absolute;
}

.work-subtitle {
	position: relative;
	width: 400px;
	font-size: 12px;
	color: var(--secondary-color);
	padding-top: 22px;
	padding-left: 50px;
}

.work-duration {
	position: relative;
	width: 400px;
	font-size: 12px;
	padding-top: 20px;
	text-align: right;
	color: var(--tertiary-color);
}

@media (min-width: 1750px) {
	.work {
		width: 19vw;
		
	}
	.work-secondary {
		width: 17vw;
	}
}

@media (max-width: 1024px) {
	.work {
		width: 80vw;
	}
	.work-secondary {
		width: 75vw;
	}
}

@media (max-width: 768px) {
	.work {
		width: 90vw;
	}
	.work-secondary {
		width: 83vw;
	}
}

@media (max-width: 425px) {
	.work {
		width: 85vw;
	}
	.work-secondary {
		width: 83vw;
		flex-direction: column;
	}
	.work-subtitle {
		position: relative
	}
	.work-duration {
		position: relative;
		text-align: left;
		padding-top: 1%;
		padding-left: 50px;
		width: 82vw;

	}
}