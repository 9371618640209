@import "../../../data/styles.css";

.education-body {
    margin-bottom: 3%;
}

.education {
    display: flex;
    padding-bottom: 40px;
    width: 30vw;
    margin-bottom: 3%;
}

.education-image {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    outline: 6px solid white;
    box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.25);
}

.education-title {
    font-size: 15px;
    font-weight: 700;
    padding-left: 20px;
    margin-top: -3px;
    color: var(--secondary-color);
}

.education-secondary {
    display: flex;
    width: 27vw;
    /* flex-direction: column; */
    position: absolute;
}

.education-subtitle {
    position: relative;
    width: 400px;
    font-size: 12px;
    color: var(--secondary-color);
    padding-top: 20px;
    padding-left: 50px;
}

.education-duration {
    position: relative;
    width: 400px;
    font-size: 12px;
    padding-top: 20px;
    text-align: right;
    color: var(--tertiary-color);
}

@media (min-width: 1750px) {
    .education {
        width: 19vw;

    }

    .education-secondary {
        width: 17vw;
    }
}

@media (max-width: 1024px) {
    .education {
        width: 80vw;
    }
    .education-secondary {
        width: 75vw;
    }
}

@media (max-width: 768px) {
    .education {
        width: 90vw;
    }
    .education-secondary {
        width: 83vw;
    }
}

@media (max-width: 425px) {
    .education {
        width: 85vw;
    }

    .education-secondary {
        width: 83vw;
        flex-direction: column;
    }

    .education-subtitle {
        /* width: 70vw; */
        /* display: block; */
        position: relative;
    }

    .education-duration {
        position: relative;
        /* justify-content: flex-start; */
        padding-left: 50px;
        padding-top: 1%;
        text-align: left;
        width: 82vw;
    }
}