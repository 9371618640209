@import "../../../data/styles.css";

.footer {
	display: flex;
	/* padding-top: 25px; */
	/* padding-bottom: 25px; */
	background-color: #fafafa;
	align-items: flex-end;
	height: 100px;
	background-image: url('../../../../public/footer_animation.svg');
	overflow-x: hidden;
}

html {
	overflow-x: hidden;
}

body {
	overflow-x: hidden;
}

.footer-links {
	flex-basis: 40%;
	margin-left: 5%;
}

.footer-socials {
	flex-basis: 50%;
	display: flex;
	margin-right: 7%;
	justify-content: flex-end;
}

.footer-nav-link-list {
	display: flex;
	list-style: none;
	justify-content: space-between;
	align-items: center;
}

.footer-nav-link-item {
	font-weight: bold;
	font-size: 80%;
}

.footer-nav-link-list a {
	text-decoration: none;
	color: white;
}

.footer-nav-link-list a:hover {
	color: gray;
}

.footer-social-icon {
	color: white;
	padding-right: 20px;
	font-size: 25px;
	transition: color 0.2s ease-in-out;

}

@media (max-width: 600px) {
	.footer {
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		height: 100%;
		padding-bottom: 0;
	}

	.footer-links {
		/* background-color: blue; */
		align-items: center;
		width: 75%;
		margin-left: 0;
		padding-left: 0;
		padding-top: 10%;
	}

	.footer-nav-link-list {
		width: 100%;
		/* background-color: red; */
		align-items: center;
		padding-left: 0;
	}

	.footer-socials {
		/* justify-content: center; */
		align-items: center;
		justify-content: center;
		margin-right: 0;
		padding-right: 0;
		margin-top: auto;
		/* bottom: 0; */
	}
}