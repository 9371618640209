.card {
	mix-blend-mode: normal;
	border-radius: 20px;
	outline: 2px solid #f4f4f5;
	max-width: 100%;
	/* Prevent overflow on smaller screens */
	margin: 0 auto;
	/* Center the card horizontally */
}

.card-container {
	padding: 20px 20px 0 20px;
	/* Adjust padding as needed */
}

.card-header {
	display: flex;
	align-items: center;
	padding-bottom: 5%;
}

.card-icon {
	font-size: 15px;
	color: var(--tertiary-color);
}

.card-title {
	color: var(--secondary-color);
	font-size: 14px;
	padding-left: 10px;
	/* Adjust as needed */
	font-weight: 650;
}

.card-body {
	padding-top: 20px;
	/* Adjust padding as needed */
	font-size: 15px;
}

@media (max-width: 1024px) {
	.card-title {
		font-size: 12px;
		/* Adjust font size for smaller screens */
	}

	.card-body {
		padding-top: 10px;
		/* Adjust padding for smaller screens */
		font-size: 14px;
		/* Adjust font size for smaller screens */
	}

	.card-header {
		padding-bottom: 1%;
	}
}