.resume-logo-container {
    display: flex;
    justify-content: left;
    padding-top: 25px;
}

.resume-logo {
    display: flex;
    position: fixed;
    border: 1px solid white;
    border-radius: 50%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    top: 4vh;
}

.resume-subtitle {
    width: 100% !important;
}

.resume-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin-top: 120px;
}


.socials-container {
    display: flex;
    flex-direction: column;
    margin-top: 80px;
}

.resume-socials {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}