.education-and-work-container {
    display: flex;
    justify-content: center;
    gap: 7%;
    align-items: flex-start;
    width: 100%;
}

@media (max-width: 1024px) {
    .education-and-work-container {
        justify-content: center;
        flex-direction: column;
        max-width: 100%;
    }

    .education-container,
    .work-container {
        width: 100%;
        padding: 10px;
        /* Adjust the padding to create spacing between cards */
    }
}