.projects-logo-container {
	display: flex;
	justify-content: left;
	padding-top: 25px;
}

.projects-main {
	display: flex;

}

.projects-logo {
	display: flex;
	position: fixed;
	border: 1px solid white;
	border-radius: 50%;
	box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
	top: 4vh;
}

.projects-container {
	display: flex;
	flex-direction: column;
	height: 100%;
	margin: 0;
	padding-top: 120px;
}

.projects-left-side {
	flex-basis: 70%;

}

.projects-right-side {
	flex-basis: 30%;
}

.projects-image-container {
	/* width: 370px;	 */
}

.projects-title {
	width: 100% !important;
}

.projects-subtitle {
	width: 100% !important;
}

.projects-image-wrapper {
	display: flex;
	overflow: hidden;
	width: 100%;
	border-radius: 10%;
	/* transform: rotate(3deg); */
}

@media (max-width: 600px) {
	.projects-container {
		padding-top: 60px;
	}

	.projects-project {
		width: calc(100% / 2);
	}
}